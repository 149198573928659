import { enhancedFetch, LoginPlugin, ssrBuilder } from 'journey-ui'
import { NextPageContext } from 'next'
import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import { AppPage } from '../components/AppPage'
import { AppContext } from '../contexts/AppContext'
import { BrandHeader } from '../components/BrandHeader'
import { redirectIfNoBrand } from '../helpers'

interface Props {
  brand: Brand
}

const LoginPage: React.FC<Props> = ({ brand }) => {
  const router = useRouter()
  const [, setCookie] = useCookies(['last_logged_in_at'])
  const { setUser } = useContext(AppContext)

  const submitForm = async (form) => {
    const user = await enhancedFetch<User>('/api/auth/login', {
      method: 'POST',
      body: form,
    })

    /*
     Set this cookie so that we can verify if they ever logged in before which only then
     we will send out an "api/auth/user" request to get their user credentials.
    */
    setCookie('last_logged_in_at', `${Date.now()}`)

    setUser(user)
    setTimeout(() => {
      router.push('/')
        .catch(console.error)
    }, 0)
  }

  return (
    <AppPage brand={brand} title={brand.name} description="Login to view your orders and rewards." className="login-page">
      <LoginPlugin
        onSubmit={submitForm}
        backgroundUrl="https://pizzamico8-dev.s3.amazonaws.com/branding/pizza-man-cover.png"
      />
    </AppPage>
  )
}

// noinspection JSUnusedGlobalSymbols (PHPStorm doesn't detect NextJS's pages)
export default LoginPage

export async function getServerSideProps(ctx) {
  return ssrBuilder(ctx)
    .setProp('brand', (fetcher) => fetcher(`/api/brand/${ctx.req.headers.host}`))
    .onError(redirectIfNoBrand)
    .get()
}
